<template>
  <layout>
    <template #header>
      <app-header></app-header>
    </template>
    <div class="content">
      <v-card class="container" rounded="xl">
        <h1 class="top-title">Create new user</h1>
        <user-create-form @submit="submit" @cancel="cancel" />
      </v-card>
    </div>
  </layout>
</template>
<script>
import UserCreateForm from "../../../users/components/UserForm.vue";
import Header from "../../../app/components/headers/DefaultHeader";
import DefaultLayout from "../../../app/components/layouts/DefaultLayout";
import { createUser } from "../../../users/user-create/user-create.js";

export default {
  name: "UserCreate",
  metaInfo: {
    title: "New User",
  },
  components: {
    "user-create-form": UserCreateForm,
    "app-header": Header,
    layout: DefaultLayout,
  },
  methods: {
    cancel() {
      this.$router.push("/system-admin/users");
    },
    async submit({ user, skills, values, interests }) {
      try {
        const newUser = await createUser({
          user,
          skills,
          values,
          interests,
          mode: "CREATE",
        });
        this.$router.push("/users/" + newUser.id);
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while creating user"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 700px;
  width: 60%;
  flex: 1;
  margin: 0 auto;
  margin-top: 15px;
  padding: 25px 40px;
  .top-title {
    color: $inqliGreen;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
